<template>

  <!-- Table Container Card -->
    <b-card
        v-bind:no-body="props.title ? false : true"
    >
        <b-card-title v-if="props.title">
            <h3>{{props.title}}
                    <b-button :to="{ name: 'apps-invoice-bankconfirm-list'}" class="float-right btn-sm" variant="primary">View All</b-button>
            </h3>
        </b-card-title>

    <div class="m-2">

      <!-- Table Top -->
        <b-row v-if="!props.isWidget">

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <v-select
                  v-model="paymentFilter"
                  :options="paymentOptions"
                  class="invoice-filter-select"
                  placeholder="Select Payment"
              >
                  <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
                  </template>
              </v-select>
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="invoice-filter-select"
              placeholder="Select Status"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>

    </div>
        <b-overlay
            spinner-variant="primary"
            id="overlay-background"
            :show="loadTable"
            variant="transparent"
            opacity="1.00"
            blur="0.5em"
            rounded="sm"
        >
            <b-table
                :busy.sync="loadTable"
      ref="refInvoiceListTable"
      :items="fetchInvoices"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
        <template #cell(nomor_order)="data">
            <b-link
                :to="{ name: 'apps-invoice-preview', params: { id: data.item.nomor_order }}"
                class="font-weight-bold"
            >
                # {{ data.value }}
            </b-link>
        </template>
        <template #head(status_pembayaran)>
            <feather-icon
                icon="TrendingUpIcon"
                class="mx-auto"
            />
        </template>
        <template #cell(status_pembayaran)="data">
            <b-avatar
                :id="`invoice-row-${data.item.kd_transaksi}`"
                size="32"
                :variant="`light-${resolveInvoiceStatusVariantAndIcon(data.item.status_pembayaran).variant}`"
            >
                <feather-icon
                    :icon="resolveInvoiceStatusVariantAndIcon(data.item.status_pembayaran).icon"
                />
            </b-avatar>
            <b-tooltip
                :target="`invoice-row-${data.item.kd_transaksi}`"
                placement="top"
            >
                <p class="mb-0">
                    {{ data.item.status_pembayaran }}
                </p>
            </b-tooltip>
        </template>
        <template #cell(user)="data">
            <b-media vertical-align="center">
                <template #aside>
                    <b-avatar
                        size="32"
                        :src="data.item.avatar"
                        :text="avatarText(data.item.user.nama_customer)"
                        :variant="`light-${resolveClientAvatarVariant(data.item.invoiceStatus)}`"
                    />
                </template>
                <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.user.nama_customer }}
          </span>
                <small class="text-muted">{{ data.item.user.email }}</small>
            </b-media>
        </template>

    </b-table>
        </b-overlay>
    <div class="mx-2 mb-2">
        <b-row v-if="!props.isWidget">

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip, BOverlay,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useInvoicesList from './useInvoiceList'

import invoiceStoreModule from '../invoiceStoreModule'

export default {
  components: {
      BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
    props: {
        title: {
            type: String,
            required: false,
        },
        perPageParam: {
            type: Number,
            required: false,
        },
        isWidget: {
            type: Boolean,
            default: false
        }
    },
  setup(props) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const statusOptions = ['verified','unverified']
    const paymentOptions = ['COD','Virtual Account','Transfer Bank']

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      paymentFilter,
      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList()

    return {
        loadTable: false,
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
        props,
      statusFilter,
      paymentFilter,
      refetchData,
      paymentOptions,
      statusOptions,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
